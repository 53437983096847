<template>
  <div>
    <div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="最高学历">
            <span>{{ listData.degree || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="毕业时间">
            <span>{{ listData.graduationTime || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="GPA">
            <span>{{ listData.gpa || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="语言成绩">
            <span>{{ listData.langGrades || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="目标国家/地区">
            <span>{{ listData.intentionCountry || '-'}}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="意向服务">
            <span>{{ listData.intentionService || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="总预算">
            <span>{{ listData.budget || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="其他信息">
            <span>{{ listData.remark || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import TablePage from "@/components/TablePage";
import { customerStage, intentionLevel, customerStatus, starRating } from '@/utils/public';
import dayjs from "dayjs";
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "intentionDetail",
  data() {
    return {
      detailLoading: false,
      listData: {},
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].abroadIntentionId)
  },
  methods: {
    async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/clientUser/myStudyAbroadIntention/getStudyAbroadIntentionById", { id: id })
        this.listData = res.data;

      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

  }
}
</script>

<style scoped>
.detail-title {
  font-weight: 550;
}

.detail-text {}

.ant-col {
  margin-top: 20px;
}

.user_image {
  max-width: 224px;
  max-height: 126px;
}
</style>
